import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import CookieModal from '~/components/CookieModal'
import { useLocalStorage } from '~/hooks/LocalStorage'

import Head from './Head'
import Header from './Header'
import Footer from './Footer'
import { IBlogHeaderMenu } from 'types/blog/headerMenu'

const PublicContainer = styled.div`
  padding-top: 76px;

  ${theme.breakpoints.down('md')} {
    padding-top: 80px;
  }
`

interface IPublic {
  title?: string
  description?: string
  children: ReactNode
  indexable?: boolean
  headerMenu?: IBlogHeaderMenu[]
  showProgress?: boolean
  progressValue?: string
  seoFullHead?: string
}

const Public: FC<IPublic> = ({
  children,
  title,
  description,
  indexable,
  headerMenu,
  showProgress,
  progressValue,
  seoFullHead,
}) => {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage<string>('cookiesAccepted', 'false')
  return (
    <PublicContainer>
      <Head title={`${title || 'Provi'}`} description={description} indexable={indexable} seoFullHead={seoFullHead} />
      <Header headerMenu={headerMenu} showProgress={showProgress} progressValue={progressValue} />
      {children}
      <Footer />
      {cookiesAccepted === 'false' && typeof window !== 'undefined' && <CookieModal onAccept={setCookiesAccepted} />}
    </PublicContainer>
  )
}

export default Public
