import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 184px));
  gap: 24px;
  align-self: flex-end;
  grid-area: legal;
`

export const LegalDisclaimer = styled.span`
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  margin-bottom: 5px;
  padding: 15px 0 15px;

  grid-column: 1 / span 5;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;
  letter-spacing: 0.005em;
  margin-bottom: 30px;

  ${theme.breakpoints.down('md')} {
    grid-column: 1 / span 5;
    border-bottom: none;
    padding: 12px 0 8px;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.0075em;
  }

  ${theme.breakpoints.up('lg')} {
    grid-column: 2 / span 5;
  }
`
