import { FC } from 'react'
import { Link } from '~/components/Link'

import {
  Address,
  Li,
  CertifiedBusiness,
  Container,
  Content,
  Copyright,
  InformationContainer,
  InformationContent,
  SocialLinks,
  SocialLink,
} from './styles'
import { Ul, Img } from './Links/styles'
import Links from './Links'
import LegalText from './LegalText'

const Footer: FC = () => {
  const date = new Date()
  return (
    <Container>
      <Content>
        <Links />
        <LegalText />
        <InformationContainer>
          <InformationContent>
            <Copyright>
              ©{date.getFullYear()} Provipay Principia educação tecnologia e serviços Ltda. <br /> CNPJ 32.390.384/0001-92
            </Copyright>
            <Address>
              Av. Dr. Cardoso de Melo, 1340 cj. 11 <br /> Vila Olímpia, São Paulo/SP <br />
              04548-004
            </Address>
            <Ul>
              <Li>
                <p>WhatsApp</p>
              </Li>
              <Li>
                <Img src="/assets/svg/whatsappLogo.svg" alt="Logo do Whatsapp" title="WhatsApp" />
                <Link label="+55 (11) 95771-2414" href="https://api.whatsapp.com/send?phone=5511957712414" external />
              </Li>
              <Li>
                <p>E-mail</p>
              </Li>
              <Li>
                <Img src="/assets/svg/envelopeSimple.svg" alt="Ícone de e-mail" title="E-mail" />
                <Link label="papo@provi.com.br" href="mailto:papo@provi.com.br" external />
              </Li>
            </Ul>
            <CertifiedBusiness
              loading="lazy"
              src="/assets/svg/certified_business.svg"
              alt="Logo de Empresa B certificada"
              title="Certificado Empresa B"
            />
          </InformationContent>
          <SocialLinks>
            <Link
              href="https://www.instagram.com/sejaprovi/"
              component={
                <SocialLink loading="lazy" src="/assets/svg/instagram.svg" alt="Logo do Instagram" title="Instagram" />
              }
              external
            />
            <Link
              href="https://www.facebook.com/SejaProvi"
              component={<SocialLink loading="lazy" src="/assets/svg/facebook.svg" alt="Logo do Facebook" title="Facebook" />}
              external
            />
            <Link
              href="https://www.linkedin.com/company/provi/"
              component={<SocialLink loading="lazy" src="/assets/svg/linkedin.svg" alt="Logo do LinkedIn" title="LinkedIn" />}
              external
            />
            {/* <Link href="/" component={<SocialLink loading="lazy" src="assets/svg/medium.svg" />} external /> todo: uncomment when we have a medium */}
          </SocialLinks>
        </InformationContainer>
      </Content>
    </Container>
  )
}

export default Footer
