import { FC } from 'react'
import { Link } from '~/components/Link'

import { Container, Li, Logo, Ul } from './styles'

const Links: FC = () => (
  <Container>
    <Link href="/" component={<Logo loading="lazy" src="/assets/svg/footer_logo.svg" alt="Logo" />} />
    <Ul>
      <Li>Para parceiros</Li>
      <Li>
        <Link label="ProviPay" href="/provipay" />
      </Li>
    </Ul>
    <Ul>
      <Li>Para estudantes</Li>
      <Li>
        <Link label="Como funciona" href="/como-funciona" />
      </Li>
    </Ul>
    <Ul>
      <Li>Conteúdos</Li>
      <Li>
        <Link label="Blog" href="/blog" />
      </Li>
      <Li>
        <Link label="Cases de sucesso" href="/cases" />
      </Li>
    </Ul>
    <Ul>
      <Li>A Provi</Li>
      <Li>
        <Link label="Sobre nós" href="/quem-somos" />
      </Li>
      <Li>
        <Link label="Carreiras" href="/carreiras" />
      </Li>
      <Li>
        <Link label="Perguntas frequentes" href="/duvidas" />
      </Li>
    </Ul>
    <Ul className="align-right">
      <Li>Políticas</Li>
      <Li>
        <Link label="Termos & políticas" href="/termos-de-uso" />
      </Li>
      <Li>
        <Link label="Ética e Compliance" href="/compliance" />
      </Li>
      <Li>
        <Link label="Central de dados" href="/central-de-dados" />
      </Li>
    </Ul>
  </Container>
)

export default Links
