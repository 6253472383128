import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface IContainer {
  isOpen: boolean
  subItemIsOpen: boolean
}

interface IItemWrapper {
  open: boolean
}

export const Container = styled.nav<IContainer>`
  margin: 0 auto;
  background-color: #fff;
  min-height: ${({ subItemIsOpen }) => (subItemIsOpen ? '124px' : '76px')};

  * li {
    list-style: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;

  ${theme.breakpoints.down('md')} {
    min-height: 76px;

    ${({ isOpen }) =>
      isOpen &&
      css`
        height: 100vh;
        width: 100%;
        position: fixed;
      `}
  }
`

export const Content = styled.div`
  max-width: 1064px;
  margin: 0 auto;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    transition: all 1s ease;

    .hamburger-react {
      color: black;
      display: none;
    }

    .item-wrapper {
      margin-bottom: 0;
    }
  }

  a {
    display: flex;
    align-self: center;

    ${theme.breakpoints.down('md')} {
      svg {
        width: 82px;
        height: 25px;
      }
    }

    ${theme.breakpoints.up('md')} {
      align-self: flex-start;
    }
  }
`

export const ItemWrapper = styled.ul<IItemWrapper>`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 10;

  ${theme.breakpoints.down('md')} {
    display: ${({ open }) => (open ? 'grid' : 'none')};
    grid-gap: 4px;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    list-style: none;
    padding: 24px;
  }

  ${theme.breakpoints.up('md')} {
    > li:not(:last-of-type) {
      padding: 0 32.875px;
    }
    > li:first-child {
      padding: 0 32.875px 0 0;
    }
    > li:nth-last-child(2) {
      padding: 0 65.75px 0 32.875px;
    }
  }

  :hover {
    opacity: 1;
    visibility: visible;
  }

  li {
    list-style: none;
  }
`

interface IItem {
  isHovered?: boolean
  isOtherItemHovered?: boolean
}

const itemColor = css`
  transition: color 0.1s ease;

  :hover {
    color: ${theme.colors.bluePrimary};
  }

  :active {
    color: ${theme.colors.bluePrimaryDark};
  }
`

export const LogoLink = styled.a`
  display: flex;
  align-items: flex-end !important;
  text-decoration: none;
`

export const Item = styled.li<IItem>`
  margin: 0 5px;
  border-top: 1px solid #000000;
  list-style: none;
  &[data-open='true'] {
    margin-bottom: 66px;
  }

  a {
    display: block;
    text-decoration: none;
    color: black;
    margin-bottom: 16px;
    font-weight: normal;
  }

  ul {
    list-style: none;
  }

  span {
    p svg {
      display: none;
      ${theme.breakpoints.up('md')} {
        display: block;
        transition: all ease 0.25s;
      }
    }
  }

  :hover {
    ul {
      opacity: 1;
      visibility: visible;
      position: relative;
    }
    span {
      p svg {
        transform: rotate(180deg);
      }
    }
  }

  :nth-child(-n + 2),
  :nth-child(-n + 3),
  :nth-child(-n + 4) {
    span,
    > a {
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 13.2px;
    }
  }

  :nth-child(5) {
    span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid #000000;
      padding: 16px 0;
    }

    svg {
      margin-left: 8px;
    }

    div {
      margin-top: 15px;
    }
  }

  ${theme.breakpoints.up('md')} {
    margin: 0;
    border-top: none;

    a {
      ${itemColor};
    }

    a {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 0;
      font-weight: 600;
    }

    :nth-child(-n + 2),
    :nth-child(-n + 3),
    :nth-child(-n + 4) {
      span,
      > a {
        text-transform: none;
        font-size: 16px;
        font-weight: 600;
      }
    }

    :nth-child(3) {
      :hover {
        margin-bottom: 132px;
        ul {
          visibility: visible;
          position: absolute;
        }
      }
    }

    :nth-child(4) {
      :hover {
        margin-bottom: 66px;
        ul {
          visibility: visible;
          position: absolute;
        }
      }
    }

    :nth-child(5) {
      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 0;
        padding: 2.5px 0;
      }

      div {
        top: 35px;
      }
    }

    span,
    a {
      font-weight: 600;
      transition: color 0.1s ease;

      ${({ isOtherItemHovered, isHovered }) =>
        isOtherItemHovered &&
        css`
          color: ${isHovered ? '#000000' : theme.colors.greyPrimary};
        `}
    }
  }
`

export const Label = styled.span`
  font-size: 19px;
  margin-top: 4px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: black;
  margin-bottom: 16px;
  transition: color 0.1s ease;

  p {
    display: flex;
    align-items: center;
  }

  ${theme.breakpoints.up('md')} {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 3px;
    display: block;

    button:focus {
      outline: none;
    }

    div {
      padding-top: 14px;
      position: fixed;
      background-color: #fff;

      a {
        ${itemColor};
      }

      @media (max-width: 1180px) {
        margin-left: -75px;
      }
    }
  }
`

export const PagesWrapper = styled.ul`
  font-weight: 500;
  font-size: 19.1px;
  line-height: 32px;
  visibility: visible;

  ${theme.breakpoints.up('md')} {
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }

  li {
    position: relative;
    margin: 0;
    display: list-item;

    a {
      ${itemColor}
    }
  }
`

export const ProgressBar = styled.div<{ progressValue: string }>`
  background-color: ${theme.colors.bluePrimaryLight};
  position: relative;
  height: 6px;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 150ms linear;
    transform: translateX(${(props) => props.progressValue});
    background-color: ${theme.colors.bluePrimary};
  }
`

export const BlogHeader = styled.div`
  background-color: ${theme.colors.bluePrimaryDark};
  > div:first-child {
    height: 48px;
    max-width: 1064px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledBlogName = styled.a`
  font-weight: 600;
  color: #fff;
  text-decoration: none;
`

export const BlogMenu = styled.ul`
  position: relative;
  cursor: pointer;
  min-width: 200px;

  ${theme.breakpoints.down('md')} {
    &:hover {
      li {
        svg {
          transform: rotate(180deg);
        }
      }
      ul {
        display: block;
      }
    }
  }
  > li {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 10px;
    font-weight: 600;
    color: #fff;
    svg {
      transition: transform 150ms linear;
    }
  }

  ${theme.breakpoints.up('md')} {
    > li {
      display: none;
    }
  }
`

export const BlogMenuItem = styled.ul`
  display: none;
  position: absolute;
  top: 48px;
  left: 0;
  background-color: #fff;
  width: 100%;
  li {
    padding: 6px 10px;
    a {
      color: #000;
      text-decoration: none;
      transition: color 150ms linear;
      font-weight: 600;
    }
    &:hover {
      a {
        color: ${theme.colors.bluePrimary};
      }
    }
  }

  ${theme.breakpoints.up('md')} {
    display: flex;
    position: static;
    background-color: transparent;
    align-items: center;
    li {
      a {
        color: #fff;
        font-size: 13px;
      }
      &:hover {
        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }

  ${theme.breakpoints.up('lg')} {
    li {
      a {
        font-size: 14px;
      }
    }
  }
`
