import { useState } from 'react'
import { ModalContainer, CookieText, CookieAccept } from './CookieModal.styles'

interface ICookieModal {
  onAccept: (value: string) => void
}

const CookieModal = ({ onAccept }: ICookieModal) => {
  const [animation, setAnimation] = useState(false)
  const setAccept = () => {
    setAnimation(!animation)

    setTimeout(() => {
      onAccept('true')
    }, 300)
  }
  return (
    <ModalContainer easeIn={!animation}>
      <CookieText>
        Usamos cookies para melhorar a sua experiência, fazer análises de tráfego e garantir a segurança em nosso site. Para
        saber mais, consulte a nossa <a href="/termos-de-uso">Política de privacidade</a>.
      </CookieText>
      <CookieAccept onClick={() => setAccept()}>Aceitar</CookieAccept>
    </ModalContainer>
  )
}

export default CookieModal
