import styled from 'styled-components'

export const Container = styled.footer`
  padding: 24px;

  color: ${({ theme }) => theme.footer.foreground};
  background-color: ${({ theme }) => theme.footer.background};

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    padding: 37px;
  }
`

export const Content = styled.div`
  position: relative;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    max-width: 1016px;
    margin: 0 auto;
  }

  ${({ theme }) => theme.provi.breakpoints.down('md')} {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'links'
      'address'
      'legal';
  }
`

export const InformationContainer = styled.div`
  grid-area: address;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 184px));
    gap: 20px;
  }

  ${({ theme }) => theme.provi.breakpoints.up('lg')} {
    gap: 50px;
  }
`

export const InformationContent = styled.div`
  border-top: 2px solid #ffffff;
  margin-bottom: 44px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    grid-column: 1 / 5;
    display: grid;
    grid-template-columns: repeat(4, 150px);
    grid-gap: 20px;
    max-width: 50%;
    border-top: none;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.provi.breakpoints.up('lg')} {
    grid-column: 2 / 5;
  }
`

export const Copyright = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;

  ${({ theme }) => theme.provi.breakpoints.down('md')} {
    padding: 13px 0 12px 0;
  }
`

export const Address = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;
  padding-bottom: 8px;
`
export const Li = styled.li`
  display: flex;
  p {
    font-weight: 500;
  }
  &:first-of-type {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    padding: 0;

    ${({ theme }) => theme.provi.breakpoints.up('md')} {
      letter-spacing: 0.015em;
    }
  }

  font-weight: 20px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.005em;
  padding: 4px 0;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    letter-spacing: 0.0075em;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.footer.foreground};
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.provi.colors.bluePrimaryLight};
    }
  }
`

export const CertifiedBusiness = styled.img`
  max-height: 70px;
  margin-top: 25px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    margin-top: 0;
  }
`

export const SocialLinks = styled.div`
  display: grid;
  position: absolute;
  right: 0;
  top: 53px;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    position: static;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 7px;
    margin-left: 11px;
  }

  ${({ theme }) => theme.provi.breakpoints.down('md')} {
    grid-gap: 10px;
  }
`

export const SocialLink = styled.img`
  height: 36px;
  width: 36px;
`
