import { Container, LegalDisclaimer } from './styles'

const LegalText = () => (
  <Container>
    <LegalDisclaimer>
      A Provi, por meio de plataforma online, atua como correspondente bancário de determinadas instituições financeiras, nos
      termos da Resolução do Conselho Monetário Nacional (CMN) nº 2.935, de 29 de julho de 2021, a fim de viabilizar aos seus
      usuários a contratação de empréstimos e financiamentos de custos decorrentes da contratação de serviços educacionais junto
      a certas instituições de ensino parceiras da Provi. Atualmente, a Provi atua como correspondente bancário da Money Plus
      SCMEPP Ltda (CNPJ: 11.581.339/0001-45).
    </LegalDisclaimer>
  </Container>
)

export default LegalText
