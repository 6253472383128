import styled from 'styled-components'
import { theme } from '@provi/provi-components'

interface IModalContainer {
  easeIn: boolean
}

export const ModalContainer = styled.div<IModalContainer>`
  z-index: 201;
  @keyframes slideInFromLeft {
    0% {
      visibility: hidden;
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    } 
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  animation: ${({ easeIn }) => (easeIn ? 'slideInFromLeft 200ms ease-out;' : '0.2s ease-out 0.2s 1 slideInFromRight;')}
  border: 2px solid #d4daf7;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;

  ${theme.breakpoints.down('md')} {
    position: fixed;
    left: 24px;
    right: 24px;
    height: 154px;
    top: 88px;
  }
  ${theme.breakpoints.up('md')} {
    position: fixed;
    bottom: 64px;
    left: 28px;
    height: 132px;
    width: 496px;
  }
`

export const CookieText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12.8px;
  line-height: 20px;

  a {
    text-decoration: underline;
    color: #000;
  }

  ${theme.breakpoints.down('md')} {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.0075em;
  }
`

export const CookieAccept = styled.button`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2647d7;
  background: none;
  border: 0;
  margin-top: 16px !important;

  :hover {
    cursor: pointer;
  }

  ${theme.breakpoints.down('md')} {
    font-weight: 600;
    font-size: 13.2px;
    line-height: 20px;
    margin-top: 30px !important;
  }
`
