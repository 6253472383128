import { useState, useCallback } from 'react'
import NextLink from 'next/link'
import { theme, TextButton as Button } from '@provi/provi-components'
import HamburgerMenu from 'hamburger-react'
import { LogoFullBlue, Logo } from '~/assets/svg'

import { useWindowSize } from './useWindowSize'
import {
  Container,
  Content,
  ItemWrapper,
  Item,
  Label,
  PagesWrapper,
  LogoLink,
  ProgressBar,
  BlogHeader,
  StyledBlogName,
  BlogMenu,
  BlogMenuItem,
} from './styles'
import { ArrowExpand } from '~/assets/svg/arrowExpand'
import { IBlogHeaderMenu } from 'types/blog/headerMenu'

interface IHeader {
  headerMenu?: IBlogHeaderMenu[]
  showProgress?: boolean
  progressValue?: string
}

const Header = ({ headerMenu, showProgress, progressValue }: IHeader) => {
  const [open, setOpen] = useState(false)
  const [subItemIsOpen, setSubItemIsOpen] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState('')

  const handleHover = useCallback(
    (hoverName: string, isItemHovered: boolean) => {
      if (isItemHovered) {
        setIsHovered(hoverName)
        return
      }
      setIsHovered('')
    },
    [isHovered, setIsHovered]
  )

  const isOtherItemHovered = !!isHovered.length

  const handleOnToggleHamburgerMenu = (): void => setOpen((b) => !b)

  /** hover do entrar */
  const showEnterItems = () => {
    setSubItemIsOpen(!subItemIsOpen)
    handleHover('enter', true)
  }

  const hideEnterItems = () => {
    setSubItemIsOpen(!subItemIsOpen)
    handleHover('enter', false)
  }

  /**  cores do entrar */
  const enterColor = (hover: string) => {
    if (hover === 'enter') {
      return '#000000'
    }
    if (hover === '') {
      return theme.colors.bluePrimary
    }
    return theme.colors.greyPrimary
  }

  const size = useWindowSize()

  return (
    <Container isOpen={open} subItemIsOpen={subItemIsOpen}>
      <Content>
        {!!size.width && (
          <NextLink href="/" legacyBehavior>
            <LogoLink href="/">{size.width > 908 || size.width < 800 ? <LogoFullBlue /> : <Logo />}</LogoLink>
          </NextLink>
        )}
        <HamburgerMenu size={24} toggled={open} onToggle={handleOnToggleHamburgerMenu} />

        <ItemWrapper open={open} className="item-wrapper">
          <>
            <Item
              onMouseEnter={() => handleHover('solution', true)}
              onMouseLeave={() => handleHover('solution', false)}
              isHovered={isHovered === 'solution'}
              isOtherItemHovered={isOtherItemHovered}
            >
              <Label>
                <p>
                  Soluções <ArrowExpand />
                </p>
              </Label>
              <PagesWrapper>
                <li>
                  <NextLink href="/provipay">ProviPay</NextLink>
                </li>
              </PagesWrapper>
            </Item>
            <Item
              onMouseEnter={() => handleHover('students', true)}
              onMouseLeave={() => handleHover('students', false)}
              isHovered={isHovered === 'students'}
              isOtherItemHovered={isOtherItemHovered}
            >
              <Label>
                <p>
                  Para estudantes <ArrowExpand />
                </p>
              </Label>
              <PagesWrapper>
                <li>
                  <NextLink href="/como-funciona">Como funciona</NextLink>
                </li>
              </PagesWrapper>
            </Item>
            <Item
              onMouseEnter={() => handleHover('provi', true)}
              onMouseLeave={() => handleHover('provi', false)}
              isHovered={isHovered === 'provi'}
              isOtherItemHovered={isOtherItemHovered}
            >
              <Label>
                <p>
                  A Provi <ArrowExpand />
                </p>
              </Label>
              <PagesWrapper>
                <li>
                  <NextLink href="/quem-somos">Sobre nós</NextLink>
                </li>
                <li>
                  <NextLink href="/carreiras">Carreiras</NextLink>
                </li>
                <li>
                  <NextLink href="/duvidas">Perguntas frequentes</NextLink>
                </li>
                <li>
                  <NextLink href="/compliance">Ética e Compliance</NextLink>
                </li>
              </PagesWrapper>
            </Item>
            <Item
              onMouseEnter={() => handleHover('conteudos', true)}
              onMouseLeave={() => handleHover('conteudos', false)}
              isHovered={isHovered === 'conteudos'}
              isOtherItemHovered={isOtherItemHovered}
            >
              <Label>
                <p>
                  Conteúdos <ArrowExpand />
                </p>
              </Label>
              <PagesWrapper>
                <li>
                  <NextLink href="/blog/">Blog</NextLink>
                </li>
                <li>
                  <NextLink href="/cases">Cases de sucesso</NextLink>
                </li>
              </PagesWrapper>
            </Item>
            <Item data-open={subItemIsOpen}>
              <Label onMouseEnter={() => showEnterItems()} onMouseLeave={() => hideEnterItems()}>
                <Button text="Entrar" icon disabledColor={enterColor(isHovered)} disabled />
                {subItemIsOpen ? (
                  <div>
                    <a href="https://painel.provi.com.br" target="_blank" rel="noopener noreferrer">
                      Painel do parceiro
                    </a>
                    <a href="https://login.provi.com.br" target="_blank" rel="noopener noreferrer">
                      Painel do aluno
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </Label>
            </Item>
          </>
        </ItemWrapper>
      </Content>
      {headerMenu && (
        <BlogHeader>
          <div>
            <NextLink href="/blog" passHref legacyBehavior>
              <StyledBlogName>Blog</StyledBlogName>
            </NextLink>
            <BlogMenu>
              <li>
                Categorias
                <ArrowExpand fill="#fff" />
              </li>
              <BlogMenuItem>
                {headerMenu.map((menu, index) => (
                  <li key={String(index)}>
                    <NextLink href={`/blog/${menu.node.slug}`} legacyBehavior>
                      {menu.node.name}
                    </NextLink>
                  </li>
                ))}
              </BlogMenuItem>
            </BlogMenu>
          </div>
        </BlogHeader>
      )}
      {showProgress && progressValue && <ProgressBar progressValue={progressValue} />}
    </Container>
  )
}

export default Header
