import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-gap: 28px;

  font-size: 13px;
  line-height: 20px;

  padding-bottom: 43px;
  grid-area: links;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    grid-template-columns: repeat(5, minmax(0, 184px));
    grid-gap: 24px;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 34px;

    .align-right {
      grid-column: 5;
    }
  }
`

export const Ul = styled.ul`
  list-style: none;

  :nth-child(5) {
    li {
      ${({ theme }) => theme.provi.breakpoints.down('md')} {
        display: flex;
        align-items: center;
      }
    }
  }
`

export const Li = styled.li`
  &:first-of-type {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    padding: 0;

    ${({ theme }) => theme.provi.breakpoints.up('md')} {
      margin-bottom: 8px;
      letter-spacing: 0.015em;
    }
  }

  font-weight: 500;
  letter-spacing: 0.005em;
  padding: 4px 0;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    letter-spacing: 0.0075em;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.footer.foreground};
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.provi.colors.bluePrimaryLight};
    }
  }
`

export const Logo = styled.img`
  max-height: 24px;
  cursor: pointer;

  ${({ theme }) => theme.provi.breakpoints.up('md')} {
    max-height: 42px;
  }
`

export const Img = styled.img`
  margin-right: 6px;
  min-width: 16px;
`
