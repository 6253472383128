import NextHead from 'next/head'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'

interface IHead {
  title?: string
  description?: string
  indexable?: boolean
  seoFullHead?: string
}

const Head = ({ title, description, indexable, seoFullHead }: IHead) => {
  const site = 'https://provi.com.br'
  const { asPath, pathname } = useRouter()
  const canonicalURL = pathname === '/' ? site : site + asPath

  return (
    <NextHead>
      {title && <title>{title}</title>}
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta
        name="keywords"
        content="financiamento estudantil, plataforma de vendas online , Income Share Agreement, plataformas de emprego, credito estudantil, financiamento educacional"
      />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} data-react-helmet="true" />
        </>
      )}
      {indexable && <link rel="canonical" href={canonicalURL} />}
      {seoFullHead && parse(seoFullHead)}
      {process.env.NEXT_PUBLIC_API_ENV === 'production' && pathname !== '/area-de-cobranca' && (
        <script src="//embed.typeform.com/next/embed.js" />
      )}
    </NextHead>
  )
}

export default Head
