import { ReactNode } from 'react'
import NextLink from 'next/link'
import { A } from './styles'

interface ILink {
  component?: ReactNode
  external?: boolean
  href: string
  label?: string
}

export const Link = ({ component, external = false, href, label }: ILink) => {
  if (external) {
    return (
      <A target="_blank" href={href} rel="noopener noreferrer">
        {component || label}
      </A>
    )
  }

  return (
    <NextLink passHref href={href} legacyBehavior>
      {component || <A>{label}</A>}
    </NextLink>
  )
}
